import { ITrackerState, TrackerAction, TrackerActionType } from "./types";

export const initialState: ITrackerState = {
  events: {},
  resources: {}
};

export function trackerReducer(state = initialState, action?: TrackerAction) {
  if (action === undefined) {
    return state;
  }
  switch (action.type) {
    case TrackerActionType.ADD_EVENT:
      const newEventId = action.payload.resource.id;
      return {
        ...state,
        events: {
          ...state.events,
          [newEventId]: action.payload.event
        },
        resources: {
          ...state.resources,
          [newEventId]: action.payload.resource
        }
      };
    default:
      return state;
  }
}
