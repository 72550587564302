export type ResourceId = string;

// resource associated with event by id
export interface ITrackerResource {
  productivityLevel: string;
  moodLevel: string;
  id: ResourceId;
  title: string;
}

// event associated with resource by id
export interface ITrackerEvent {
  start: Date;
  end: Date;
  id: ResourceId;
  initialized: boolean;
}

// enum of actions types
export enum TrackerActionType {
  ADD_EVENT = "ADD_EVENT"
}

// base interface for tracker actions
export interface ITrackerBaseAction {
  type: TrackerActionType;
}

// interface for tracker add action
export interface ITrackerAddAction extends ITrackerBaseAction {
  type: TrackerActionType.ADD_EVENT;
  payload: { event: ITrackerEvent; resource: ITrackerResource };
}

export type TrackerAction = ITrackerAddAction;

export interface ResourceTMap<T> {
  [key: string]: T;
}

export interface ITrackerState {
  resources: Record<ResourceId, ITrackerResource>;
  events: Record<ResourceId, ITrackerEvent>;
}
