import * as store from "store";
import { AppState } from ".";

const loadState: () => AppState | undefined = () => {
  const state: AppState | undefined = store.get("state2", undefined);
  if (state !== undefined) {
    Object.keys(state.tracker.events).forEach(k => {
      state.tracker.events[k].start = new Date(state.tracker.events[k].start);
      state.tracker.events[k].end = new Date(state.tracker.events[k].end);
    });
  }
  return state;
};

const saveState: (state: AppState) => void = state => {
  return store.set("state2", state);
};

export { loadState, saveState };
