import React from "react";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { Calendar as BigCal, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "./calendar.scss";
import { createStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/styles";
import { ITrackerEvent, ITrackerResource } from "../../store/tracker/types";
import uuid from "uuid/v4";
import { useSelector } from "react-redux";
import { AppState } from "../../store";

const DragAndDropCalendar = withDragAndDrop(BigCal);

enum RoundTo {
  HOUR = 60 * 60 * 1000, // milliseconds in an hour
  MINUTES15 = 15 * 60 * 1000, // milliseconds in 15 minutes
  MINUTES30 = 30 * 60 * 1000 // milliseconds in 30 minutes
}

function roundTime(
  date: Date,
  roundTo: RoundTo,
  roundFunc: (n: number) => number = Math.round
) {
  return new Date(roundFunc(date.getTime() / roundTo) * roundTo);
}

const styles = () => createStyles({});

interface ICalendarProps extends WithStyles<typeof styles> {
  newEvent: ITrackerEvent;
  setNewEvent: React.Dispatch<React.SetStateAction<ITrackerEvent>>;
  eventResource: ITrackerResource;
}

const Calendar = withStyles(styles)((props: ICalendarProps) => {
  const localizer = momentLocalizer(moment); // or globalizeLocalizer

  const now = Date.now();
  const minTime = roundTime(
    new Date(new Date(now).setHours(new Date(now).getHours() - 3)),
    RoundTo.MINUTES30,
    Math.ceil
  );
  const maxTime = roundTime(
    new Date(new Date(now).setMinutes(new Date(now).getMinutes())),
    RoundTo.MINUTES30,
    Math.ceil
  );

  const events = useSelector((state: AppState) => state.tracker.events);
  const resources = useSelector((state: AppState) => state.tracker.resources);

  const eventFake: ITrackerEvent = {
    start: new Date(new Date(now).setHours(new Date(now).getHours() + 24)),
    end: new Date(new Date(now).setHours(new Date(now).getHours() + 24)),
    id: uuid(),
    initialized: false
  };

  return (
    <div>
      <DragAndDropCalendar
        localizer={localizer}
        events={[...Object.values(events), eventFake, props.newEvent].filter(
          e => e.end >= minTime
        )}
        style={{ height: "500px" }}
        defaultView="day"
        views={["day"]}
        toolbar={false}
        selectable
        min={minTime}
        max={maxTime}
        getNow={() => new Date(now)}
        step={5}
        timeslots={6}
        slotPropGetter={slotTime => {
          let slotStyle: React.CSSProperties = {};
          if (slotTime > new Date(now)) {
            slotStyle = {
              ...slotStyle,
              backgroundColor: "#e3e5e6"
            };
          }
          return { style: slotStyle };
        }}
        eventPropGetter={event => {
          let eventStyle: React.CSSProperties = {};

          eventStyle = { ...eventStyle, whiteSpace: "pre-wrap" };

          if (!event.initialized) {
            eventStyle = { ...eventStyle, display: "none" };
          }
          if (event.id !== props.newEvent.id) {
            eventStyle = { ...eventStyle, backgroundColor: "#88b5dd" };
          }
          return { style: eventStyle };
        }}
        onSelectSlot={slotInfo => {
          props.setNewEvent({
            ...props.newEvent,
            start: slotInfo.start as Date,
            end: slotInfo.end as Date,
            initialized: true
          });
        }}
        onSelecting={range => {
          function overlap(
            a: ITrackerEvent,
            b: { start: Date; end: Date }
          ): boolean {
            return !(a.end <= b.start || a.start >= b.end);
          }
          const rangeAsDates = {
            start: range.start as Date,
            end: range.end as Date
          };
          if (
            rangeAsDates.end > new Date(now) ||
            rangeAsDates.start > new Date(now)
          ) {
            return false;
          }
          return !Object.values(events).some(v => overlap(v, rangeAsDates));
        }}
        onEventDrop={info => {
          if (
            (info.end as Date) <= new Date(now) &&
            (info.start as Date) <= new Date(now)
          ) {
            props.setNewEvent({
              ...info.event,
              start: info.start as Date,
              end: info.end as Date
            });
          }
        }}
        onEventResize={info => {
          if (
            (info.end as Date) <= new Date(now) &&
            (info.start as Date) <= new Date(now)
          ) {
            props.setNewEvent({
              ...info.event,
              start: info.start as Date,
              end: info.end as Date
            });
          }
        }}
        draggableAccessor={event => event.id === props.newEvent.id}
        titleAccessor={event => {
          function createEventTitle(resource: ITrackerResource) {
            return `${resource.title ||
              ""} Prod: ${resource.productivityLevel ||
              "-"} Mood: ${resource.moodLevel || "-"}`;
          }
          if (event.id === props.newEvent.id) {
            return createEventTitle(props.eventResource);
          }
          if (event.id !== eventFake.id) {
            return createEventTitle(resources[event.id]!);
          }
          return "";
        }}
      ></DragAndDropCalendar>
    </div>
  );
});

export default Calendar;
