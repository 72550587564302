import React from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
  Theme,
  createStyles,
  Container
} from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import { ITrackerResource, ITrackerEvent } from "../../store/tracker/types";

const radioButtonStyles = (theme: Theme) => {
  return createStyles({
    root: {
      margin: theme.spacing(0),
      flex: "1 0 0"
    }
  });
};

interface IRadioButtonProps extends WithStyles<typeof radioButtonStyles> {
  value: string;
  label: string;
  disabled: boolean;
}

type RadioButtonPropsExceptStyles = Omit<
  Omit<IRadioButtonProps, "classes">,
  "disabled"
>;

const RadioButton = withStyles(radioButtonStyles)(
  (props: IRadioButtonProps) => {
    return (
      <FormControlLabel
        {...props}
        className={props.classes.root}
        control={<Radio />}
        labelPlacement="bottom"
      ></FormControlLabel>
    );
  }
);

const radioGroupStyles = (theme: Theme) =>
  createStyles({
    group: {
      flexDirection: "row",
      justifyContent: "spaceAround"
    },
    root: {
      width: "100%",
      maxWidth: theme.breakpoints.values.sm
    }
  });

interface IRadioButtonGroupProps extends WithStyles<typeof radioGroupStyles> {
  caption: string;
  name: string;
  buttons: RadioButtonPropsExceptStyles[];
  value: string;
  setValue: (value: string) => any;
  disabled: boolean;
}

const RadioButtonGroup = withStyles(radioGroupStyles)(
  (props: IRadioButtonGroupProps) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
      props.setValue(e.target.value);

    return (
      <div>
        <FormControl
          required
          component="fieldset"
          margin="normal"
          className={props.classes.root}
        >
          <FormLabel component="legend">{props.caption}</FormLabel>
          <RadioGroup
            className={props.classes.group}
            name={props.name}
            value={props.value}
            onChange={handleChange}
            row
          >
            {props.buttons.map(buttonProps => (
              <RadioButton {...buttonProps} disabled={props.disabled} />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
    );
  }
);

interface ITextInputProps {
  label: string;
  value: string;
  setValue: (value: string) => any;
  disabled: boolean;
}

const TextInput: React.FC<ITextInputProps> = (props: ITextInputProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    props.setValue(e.target.value);

  return (
    <TextField
      {...props}
      onChange={handleChange}
      fullWidth
      required
      margin="normal"
    ></TextField>
  );
};

const trackerFormStyles = (theme: Theme) =>
  createStyles({
    root: { padding: theme.spacing(2) },
    submitButton: { margin: theme.spacing(1) }
  });

interface ITrackerFormProps extends WithStyles<typeof trackerFormStyles> {
  event: ITrackerEvent;
  eventResource: ITrackerResource;
  setEventResource: React.Dispatch<React.SetStateAction<ITrackerResource>>;
  disabled: boolean;
  handleAddEvent: () => any;
}

const TrackerForm = (props: ITrackerFormProps) => {
  // the various labels for the radio buttons
  // currently use 1-5 scale for mood and
  // productivity
  const radioButtons: RadioButtonPropsExceptStyles[] = Array(5)
    .fill(0)
    .map((_, i) => {
      i++;
      return { value: i + "", label: i + "" };
    });

  const handleSubmit = () => {
    props.handleAddEvent();
  };

  return (
    <Container maxWidth="md" className={props.classes.root}>
      <form>
        <TextInput
          disabled={props.disabled}
          label={"Note"}
          value={props.eventResource.title}
          setValue={v =>
            props.setEventResource({ ...props.eventResource, title: v })
          }
        />
        <RadioButtonGroup
          disabled={props.disabled}
          caption={"Productivity"}
          name={"Productivity"}
          buttons={radioButtons}
          value={
            (props.eventResource && props.eventResource.productivityLevel) || ""
          }
          setValue={v =>
            props.setEventResource({
              ...props.eventResource,
              productivityLevel: v
            })
          }
        />
        <RadioButtonGroup
          disabled={props.disabled}
          caption={"Mood"}
          name={"Mood"}
          buttons={radioButtons}
          value={props.eventResource.moodLevel}
          setValue={v =>
            props.setEventResource({ ...props.eventResource, moodLevel: v })
          }
        />
        <Button
          className={props.classes.submitButton}
          variant="contained"
          onClick={handleSubmit}
          disabled={props.disabled}
        >
          Save
        </Button>
      </form>
    </Container>
  );
};

export default withStyles(trackerFormStyles)(TrackerForm);
