import React, { useState, Dispatch } from "react";
import TrackerForm from "./components/TrackerForm";
import {
  CssBaseline,
  Container,
  Paper,
  Theme,
  createStyles,
  WithStyles,
  Grid
} from "@material-ui/core";
import EventCalendar from "./components/Calendar";
import { withStyles } from "@material-ui/core/styles";
import {
  ITrackerEvent,
  ITrackerResource,
  TrackerActionType,
  TrackerAction
} from "./store/tracker/types";
import uuid from "uuid";
import { useDispatch } from "react-redux";

const styles = (theme: Theme) =>
  createStyles({
    paper: { padding: theme.spacing(2) }
  });

interface ICalendarAndFormProps extends WithStyles<typeof styles> {}

const CalendarAndForm = withStyles(styles)((props: ICalendarAndFormProps) => {
  const [newEvent, setNewEvent] = useState<ITrackerEvent>(createDefaultEvent());
  const [eventResource, setEventResource] = useState<ITrackerResource>(
    createDefaultResource(newEvent)
  );

  const dispatch = useDispatch<Dispatch<TrackerAction>>();

  const handleAddEvent = () => {
    dispatch({
      type: TrackerActionType.ADD_EVENT,
      payload: {
        resource: eventResource,
        event: newEvent
      }
    });
    const resetEvent = createDefaultEvent();
    setNewEvent(resetEvent);
    setEventResource(createDefaultResource(resetEvent));
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md">
        <Paper className={props.classes.paper}>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
          >
            <Grid item md={6} sm={6} xs={12}>
              <EventCalendar
                newEvent={newEvent}
                setNewEvent={setNewEvent}
                eventResource={eventResource}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <TrackerForm
                disabled={!newEvent.initialized}
                eventResource={eventResource}
                event={newEvent}
                setEventResource={setEventResource}
                handleAddEvent={handleAddEvent}
              />
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </React.Fragment>
  );
});

/**
 * App Component
 */

interface IAppProps {}

const App: React.FC<IAppProps> = (props: IAppProps) => {
  return (
    <div>
      <CalendarAndForm />
    </div>
  );
};

export default App;

function createDefaultResource(newEvent: ITrackerEvent): ITrackerResource {
  return {
    moodLevel: "",
    productivityLevel: "",
    title: "",
    id: newEvent.id
  };
}

function createDefaultEvent(): ITrackerEvent {
  return {
    id: uuid(),
    end: new Date(),
    start: new Date(),
    initialized: false
  };
}
/**
 * Design Brief
 *
 * Show a calendar of prev events. On drag a user can create a new event.
 * A form allows the user to input metadata about the event
 * then the user hits submit
 *
 * Components
 *
 * Calendar
 * shows a list of previous events [events], [resources] is a list of
 * events and resources associated with those events. onEventCreated()
 * is a callback to invoke when an event is created.
 *
 * EventForm
 * shows options for inputting a list of events.
 */
